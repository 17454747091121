import Home from '@/admin/views/home/Home'
import Common from '@/admin/views/Common'

// 公用导入页面
const ImportView = () => import('@/admin/views/common/ImportView.vue')

// 商品管理
const NormalProductList = () => import('@/admin/views/productManage/products/NormalProductList.vue')
const SubmitProduct = () => import('@/admin/views/productManage/products/SubmitProduct.vue')
const StoreList = () => import('@/admin/views/productManage/stores/StoreList.vue')

// 上架下架商品
const UpStoreProduct = () => import('@/admin/views/productManage/stores/UpStoreProduct.vue')
const DownStoreProduct = () => import('@/admin/views/productManage/stores/DownStoreProduct.vue')
const ReOnlineProductDetail = () => import('@/admin/views/productManage/stores/ReOnlineProductDetail.vue')

// 商品分类
const ProductCategoryList = () => import('@/admin/views/productManage/productType/ProductCategoryList.vue')
const EditProductCategory = () => import('@/admin/views/productManage/productType/EditProductCategory.vue')
const LookProductList = () => import('@/admin/views/productManage/productType/LookProductList.vue')

// 订单管理
const OrderList = () => import('@/admin/views/orderManage/OrderList.vue')
const OrderDetail = () => import('@/admin/views/orderManage/OrderDetail.vue')
const RefundOrderList = () => import('@/admin/views/orderManage/afterSaleService/RefundOrderList')
const RefundOrderDetail = () => import('@/admin/views/orderManage/afterSaleService/RefundOrderDetail')

// 商城设置
const StoreSettings = () => import('@/admin/views/mallSettings/store/StoreSettings.vue')
const EditStore = () => import('@/admin/views/mallSettings/store/EditStore.vue')
const StoreDetail = () => import('@/admin/views/mallSettings/store/StoreDetail.vue')
const StoreUserSettings = () => import('@/admin/views/mallSettings/store/StoreUserSettings.vue')
const ExpressSettings = () => import('@/admin/views/mallSettings/distribution/ExpressSettings')
const ExpenseTemplate = () => import('@/admin/views/mallSettings/distribution/ExpenseTemplate')
const distributionFeeExplain = () => import('@/admin/views/mallSettings/distribution/distributionFeeExplain')
const rechargePage = () => import('@/admin/views/mallSettings/distribution/rechargePage')

// 营销工具
const CouponAudit = () => import('@/admin/views/saleTools/coupon/CouponAudit')
const CouponDetails = () => import('@/admin/views/saleTools/coupon/CouponDetails')
const CouponList = () => import('@/admin/views/saleTools/coupon/CouponList')
const CreateStoreCoupon = () => import('@/admin/views/saleTools/coupon/CreateStoreCoupon')
const CreateProductCoupon = () => import('@/admin/views/saleTools/coupon/CreateProductCoupon')

// 电子处方
const RecordList = () => import('@/admin/views/rxRecord/RecordList')
const RxDetail = () => import('@/admin/views/rxRecord/RxDetail')

// 通用设置
const CommonSettings = () => import('@/admin/views/mallSettings/common/CommonSettings')

const chargeRecordList = () => import('@/admin/views/chargeRecord/list')

const homeRoutes = {
  path: '/',
  name: 'home',
  component: Home,
  redirect: { name: 'normalProductList' },
  meta: {
    title: '主页',
    noPermission: true,
  },
  children: [
    {
      path: 'productManage',
      name: 'productManage',
      component: Common,
      meta: {
        title: '商品管理',
        isMenu: true,
        icon: 'ShopOutlined',
      },
      children: [
        {
          path: 'products',
          name: 'products',
          component: Common,
          redirect: { name: 'normalProductList' },
          meta: {
            title: '商品库',
            isMenu: true,
          },
          children: [
            {
              path: '',
              name: 'normalProductList',
              component: NormalProductList,
              meta: {},
            },
            {
              path: 'editProduct',
              name: 'editProduct',
              component: SubmitProduct,
              meta: {
                title: '编辑商品',
              },
            },
          ],
        },
        {
          path: 'stores',
          name: 'stores',
          component: Common,
          redirect: { name: 'storeList' },
          meta: {
            title: '门店商品',
            isMenu: true,
          },
          children: [
            {
              path: 'storeList',
              name: 'storeList',
              component: StoreList,
              meta: {},
            },
            {
              path: 'upStoreProduct',
              name: 'upStoreProduct',
              component: Common,
              meta: {
                title: '',
              },
              children: [
                {
                  path: '',
                  name: 'upStoreProduct',
                  component: UpStoreProduct,
                  meta: {
                    title: '门店商品管理',
                  },
                  children: [
                    {
                      path: 'upStoreProduct',
                      name: 'upStoreProduct',
                      component: UpStoreProduct,
                      meta: {
                        title: '',
                      },
                    },
                    {
                      path: '',
                      name: 'downStoreProduct',
                      component: DownStoreProduct,
                      meta: {
                        title: '',
                      },
                    },
                  ],
                },
                {
                  path: 'reOnlineProductDetail',
                  name: 'reOnlineProductDetail',
                  component: ReOnlineProductDetail,
                  meta: {
                    title: '重新上架',
                  },
                },
              ],
            },
          ],
        },
        // 商品分类
        {
          path: 'productCategory',
          name: 'productCategoryManage',
          component: Common,
          redirect: { name: 'categoryList' },
          meta: {
            title: '商品分类',
            isMenu: true,
          },
          children: [
            {
              path: '',
              name: 'categoryList',
              component: ProductCategoryList,
              meta: {},
            },
            {
              path: 'addCategory',
              name: 'addCategory',
              component: EditProductCategory,
              meta: {
                title: '新增分类',
              },
            },
            {
              path: 'editCategory',
              name: 'editCategory',
              component: EditProductCategory,
              meta: {
                title: '编辑分类',
              },
            },
            {
              path: 'lookProductList',
              name: 'lookProductList',
              component: LookProductList,
              meta: {
                title: '查看商品',
              },
            },
          ],
        },
      ],
    },
    {
      path: 'orders',
      name: 'order',
      component: Common,
      meta: {
        title: '订单管理',
        isMenu: true,
        icon: 'TagsOutlined',
      },
      children: [
        {
          path: '',
          name: 'orders',
          component: Common,
          redirect: { name: 'orderList' },
          meta: {
            isMenu: true,
            title: '订单查询',
          },
          children: [
            {
              path: '',
              name: 'orderList',
              component: OrderList,
              meta: {},
            },
            {
              path: 'detail',
              name: 'orderDetail',
              component: OrderDetail,
              meta: {
                title: '订单详情',
              },
            },
          ],
        },
        {
          path: 'refundOrders',
          name: 'refundOrders',
          component: Common,
          redirect: { name: 'refundOrderList' },
          meta: {
            isMenu: true,
            title: '售后管理',
          },
          children: [
            {
              path: '',
              name: 'refundOrderList',
              component: RefundOrderList,
              meta: {},
            },
            {
              path: 'detail',
              name: 'refundOrderDetail',
              component: RefundOrderDetail,
              meta: {
                title: '售后详情',
              },
            },
          ],
        },
      ],
    },

    {
      path: 'electronicRx',
      name: 'electronicRx',
      component: Common,
      redirect: { name: 'rxRecord' },
      meta: {
        title: '电子处方',
        isMenu: true,
        icon: 'TagsOutlined',
      },
      children: [
        {
          path: 'rxRecord',
          name: 'rxRecord',
          component: Common,
          redirect: { name: 'recordList' },
          meta: {
            title: '开方记录',
            isMenu: true,
          },
          children: [
            {
              path: 'recordList',
              name: 'recordList',
              component: RecordList,
              meta: {},
            },
            {
              path: 'rxDetail',
              name: 'rxDetail',
              component: RxDetail,
              meta: { title: '处方详情' },
            },
          ],
        },
        {
          path: '',
          name: 'chargeRecord',
          component: Common,
          redirect: { name: 'chargeRecordList' },
          meta: {
            title: '充值记录',
            isMenu: true,
          },
          children: [
            {
              path: 'chargeRecordList',
              name: 'chargeRecordList',
              component: chargeRecordList,
              meta: {},
            },
          ],
        },
      ],
    },

    {
      path: 'saleTools',
      name: 'saleTools',
      component: Common,
      redirect: { name: 'coupons' },
      meta: {
        title: '营销工具',
        isMenu: true,
        icon: 'GiftOutlined',
      },
      children: [
        {
          path: 'coupons',
          name: 'coupons',
          component: Common,
          redirect: { name: 'couponAudit' },
          meta: {
            title: '优惠券',
            isMenu: true,
          },
          children: [
            {
              path: 'couponAudit',
              name: 'couponAudit',
              component: CouponAudit,
              redirect: { name: 'couponDetails' },
              meta: {
                title: '',
              },
              children: [
                {
                  path: 'couponDetails',
                  name: 'couponDetails',
                  component: CouponDetails,
                  meta: {},
                },
                {
                  path: 'couponList',
                  name: 'couponList',
                  component: CouponList,
                  meta: {},
                },
              ],
            },
            {
              path: 'createStoreCoupon',
              name: 'createStoreCoupon',
              component: CreateStoreCoupon,
              meta: {},
            },
            {
              path: 'createProductCoupon',
              name: 'createProductCoupon',
              component: CreateProductCoupon,
              meta: {},
            },
          ],
        },
      ],
    },

    {
      path: 'settings',
      name: 'mallSettings',
      component: Common,
      meta: {
        title: '商城设置',
        isMenu: true,
        icon: 'SettingOutlined',
      },
      children: [
        {
          path: 'store',
          name: 'store',
          component: Common,
          redirect: { name: 'storeSettings' },
          meta: {
            title: '门店管理',
            isMenu: true,
          },
          children: [
            {
              path: '',
              name: 'storeSettings',
              component: StoreSettings,
              meta: {},
              children: [
                {
                  path: 'storeUser',
                  name: 'storeUserSettings',
                  component: StoreUserSettings,
                  meta: {
                    title: '店员管理',
                  },
                },
              ],
            },
            {
              path: 'add',
              name: 'addStore',
              component: EditStore,
              meta: {
                title: '新增门店',
              },
            },
            {
              path: 'edit',
              name: 'editStore',
              component: EditStore,
              meta: {
                title: '编辑门店',
              },
            },
            {
              path: 'detail',
              name: 'storeDetail',
              component: StoreDetail,
              meta: {
                title: '门店详情',
              },
            },
            {
              path: 'importStore',
              name: 'importStore',
              component: ImportView,
              meta: {
                title: '批量导入门店',
              },
            },
            {
              path: 'importStoreUser',
              name: 'importStoreUser',
              component: ImportView,
              meta: {
                title: '批量导入店员',
              },
            },
          ],
        },
        {
          path: 'distribution',
          name: 'distributionSettings',
          component: Common,
          redirect: { name: 'expressSettings' },
          meta: {
            title: '配送管理',
            isMenu: true,
          },
          children: [
            {
              path: '',
              name: 'expressSettings',
              component: ExpressSettings,
              meta: {},
            },
            {
              path: 'expenseTemplate',
              name: 'expenseTemplate',
              component: ExpenseTemplate,
              meta: {
                title: '运费模板',
              },
            },
            {
              path: 'distributionFeeExplain',
              name: 'distributionFeeExplain',
              component: distributionFeeExplain,
              meta: {
                title: '配送费说明',
              },
            },
            {
              path: 'rechargePage',
              name: 'rechargePage',
              component: rechargePage,
              meta: {
                title: '配送费充值',
              },
            },
          ],
        },
        {
          path: 'common',
          name: 'commonSettings',
          component: CommonSettings,
          meta: {
            title: '通用设置',
            isMenu: true,
          },
        },
      ],
    },
  ],
}

export default homeRoutes

<template>
  <router-view></router-view>
</template>
<script>
export default {
  name: 'CommonView',
  props: {
    keepAlive: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

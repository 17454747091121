import ajax from './../ajax'
export default {
  /**
   * 商品详情
   * @param productCode

   */
  getProductDetail(productCode) {
    return ajax.useLoading().get(`/product/${productCode}`, null)
  },
  /**
   * 公有库商品详情
   * @param pubProductCode

   */
  getPublicProductDetail(pubProductCode) {
    return ajax.useLoading().get('/product/publicInfo', { pubProductCode })
  },

  /**
   * 获取商品的推广小程序码-校验
   * @param productCode

   */
  getCheckProductQrCode(productCode) {
    return ajax.useLoading().post(`/product/${productCode}/check-qr-code`, null)
  },

  /**
   * 商品小程序码
   * @param productCode
   * @param params

   */
  getProductQrCode(productCode, params) {
    return ajax.useLoading().get(`/product/${productCode}/qr-code`, params)
  },

  /**
   * 新增商品
   * @param params 商品信息

   */
  addProduct(params) {
    return ajax.useLoading().post('/product', params)
  },

  /**
   * 编辑商品
   * @param productId
   * @param params
   */
  editProduct(productId, params) {
    return ajax.useLoading().put(`/product/${productId}`, params)
  },
  /**
   * 批量上架商品
   * @param file
   */
  importStoreProduct(file) {
    return ajax.useLoading().uploadFile('/store/import/store-product', { file })
  },
  /**
   * 下载门店商品
   * @param storeCodes
   */
  downloadStoreProduct(storeCodes) {
    return ajax.useLoading().post('/store/download/store-product', { storeCodes: `${storeCodes.join(',')}` }, true)
  },
  /**
   * 批量导入商品
   * @param file
   */
  importProduct(file) {
    return ajax.useLoading().uploadFile('/product/import/product', { file })
  },
  /**
   * 单个产品上架选择门店列表
   * @param productCode
   * @param params

   */
  getOneProductPutOnStoreList(productCode, params) {
    return ajax.useLoading().get(`/product/stores/${productCode}/off-sale`, params)
  },
  /**
   * 多个产品上架选择门店列表
   * @param params
   */
  getMultiProductPutOnStoreList(params) {
    return ajax.useLoading().get('/product/stores', params)
  },
  /**
   * 单个产品下架选择门店列表
   * @param productCode
   * @param params

   */
  getOneProductPutOffStoreList(productCode, params) {
    return ajax.useLoading().get(`/product/stores/${productCode}/on-sale`, params)
  },
  /**
   * 单个商品上架至门店
   * @param productCode
   * @param storeStocks
   */
  putOnOneProductToStore(productCode, storeStocks) {
    return ajax.useLoading().put(`/product/stores/${productCode}/enable`, { storeStocks })
  },
  /**
   * 多个商品上架至门店
   * @param productCodes
   * @param storeCodes

   */
  putOnMultiProductsToStore(productCodes, storeCodes) {
    return ajax.useLoading().post('/product/stores', { productCodes, storeCodes })
  },
  /**
   * 单个商品从门店下架
   * @param productCode
   * @param storeCodes
   */
  putOffOneProductFromStore(productCode, storeCodes) {
    return ajax.useLoading().put(`/product/stores/${productCode}/disable`, { storeCodes })
  },
  // 门店
  /**
   * 门店列表
   * @param params {queryStr, pageNum, pageSize}

   */
  getStoreList(params) {
    return ajax.useLoading().get('/store-products/summary', params)
  },
  /**
   * 门店上架商品列表
   * @param storeCode
   * @param params

   */
  getStoreProductList(storeCode, params) {
    return ajax.useLoading().get(`/store-products/${storeCode}`, params)
  },
  /**
   * 查询未添加至门店的商品列表
   * @param storeCode
   * @param params

   */
  getStoreNotAddProducts(storeCode, params) {
    return ajax.useLoading().get(`/store-products/${storeCode}/not-add`, params)
  },
  /**
   * 批量上架商品到门店
   * @param storeCode
   * @param productCodes

   */
  putOnProductsToStore(storeCode, productCodes) {
    return ajax.useLoading().put(`/store-products/${storeCode}/enable`, { productCodes })
  },
  /**
   * 批量下架门店商品
   * @param storeCode
   * @param productCodes
   */
  putOffProductsFromStore(storeCode, productCodes) {
    return ajax.useLoading().put(`/store-products/${storeCode}/disable`, { productCodes })
  },
  /**
   * 批量上架商品到门店
   * @param storeCode
   * @param productCodes
   */
  deleteProductsFromStore(storeCode, productCodes) {
    return ajax.useLoading().delete(`/store-products/${storeCode}?productCodes=${productCodes.join(',')}`, null)
  },
  /**
   * 将商品添加至门店
   * @param storeCode
   * @param productCodes
   */
  addProductsToStore(storeCode, productCodes) {
    return ajax.useLoading().post(`/store-products/${storeCode}`, { productCodes })
  },

  /**
   * 获取排序组合后的商品分类
   */
  getProductTypes() {
    return ajax.useLoading().get('/product-type/get-product-type-list', null)
  },

  /**
   * 审核列表各状态数量
   */
  getCheckListCount() {
    return ajax.useLoading().get('/product/checkListCount', null)
  },

  /**
   * 商品库列表-数量（v1.4）
   */
  getProductMsgCount() {
    return ajax.useLoading().get('/product/listCount', null)
  },

  /**
   * 商品库列表
   * @param params
   */
  getProductLibList(params) {
    return ajax.useLoading().get('/product/list', params)
  },

  /**
   * 门店商品数量
   * @param storeCode
   */
  getStoreProductCount(storeCode) {
    return ajax.useLoading().get(`/store-products/${storeCode}/count`, null)
  },

  /**
   * 下载条形码匹配公有库商品列表模板
   */
  downloadPublicLibraryTemplate() {
    return ajax.useLoading().download('/product/download/publicLibraryTemplate')
  },

  /**
   * 门店商品-批量更新商品
   */
  brtchUpdateGoodsInfo(storeCode, file) {
    return ajax.useLoading().uploadFile(`/store-products/${storeCode}/importStock`, { file })
  },

  /**
   * 门店商品-导出商品
   */
  importGoodsList(storeCode, params) {
    return ajax.useLoading().download(`/store-products/${storeCode}/list-export`, params)
  },

  /**
   * 商品库-导出
   */
  importStockList(params) {
    return ajax.useLoading().download('/product/list-export', params)
  },

  /**
   * 编辑库存
   * @param storeCode
   * @param params
   */
  editProductStock(storeCode, params) {
    return ajax.useLoading().put(`/store-products/${storeCode}/editProductStock`, params)
  },

  /**
   * 下载图片
   * @param params
   */
  downloadImg(params) {
    return ajax.useLoading().download('/downloadImg', params)
  },
}

import ajax from './../ajax'

export default {
  login(loginName, password, captcha, id) {
    return ajax.useLoading().post('/sign-in', {
      loginName,
      password,
      captcha,
      id,
    })
  },
  logout() {
    return ajax.useLoading().post('/logout')
  },
  changePassword(oldPwd, newPwd, confirmPwd) {
    return ajax.useLoading().post('/pwd', { oldPwd, newPwd, confirmPwd })
  },
}

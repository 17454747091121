<template>
  <a-modal
    v-model:visible="modalVisible"
    :mask-closable="false"
    title="消息通知"
    wrap-class-name="newsBox"
    :width="560"
    :footer="null"
    centered
    class="news-notice-modal"
  >
    <a-layout class="modalbody">
      <template v-if="noticeList.length">
        <a-row v-for="item of noticeList" :key="item.id" class="item">
          <a-col class="content">
            <span v-if="!item.isRead" class="tips">NEW</span>
            {{ item.content }}
          </a-col>
          <a-col class="time">
            {{ $dayjs(item.ctime).format('YYYY-MM-DD HH:mm') }}
          </a-col>
        </a-row>
      </template>
      <a-empty v-else class="tw-my-50px" description="暂无消息" />
    </a-layout>
  </a-modal>
</template>

<script>
import noticeApi from '@/admin/api/modules/noticeApi'
import store from '../../../store'

export default {
  name: 'NewNoticeModal',
  props: {},
  data() {
    return {
      noticeList: [],
      modalVisible: true,
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      noticeApi.getNoticeList().then(data => {
        this.noticeList = data
        noticeApi.getNoticeNum().then(data1 => {
          store.commit('updateNoticeNum', data1)
        })
      })
    },
  },
}
</script>

<style lang="less" scoped>
.news-notice-modal {
  .modalbody {
    margin: -24px;
    padding: 8px 24px;
    background: #f5f5f5;
    max-height: 562px;
    min-height: 200px;
    overflow-y: auto;
  }
  .item {
    background: #ffffff;
    padding: 16px 24px;
    margin: 8px 0;
  }
  .content {
    font-size: 14px;
    color: #333333;
  }
  .tips {
    font-size: 12px;
    color: #fa5050;
    border: 1px solid #fa5050;
    height: 18px;
    line-height: 18px;
    padding: 0 2px;
  }
  .time {
    font-size: 12px;
    color: #999999;
    margin-top: 8px;
  }
}
</style>

import axios from 'axios'
import qs from 'qs'
import store from '../store'
import { message } from 'ant-design-vue'
import router from '../router'
import versionConfig from '@/common/utils/versionConfig'

class Ajax {
  // Instance
  constructor() {
    this.autoShowToast = true // 错误的时候是否自动弹出toast
    this.loadingCount = 0 // 记录当前需要显示Loading的请求数，大于0时需要显示loading
    this.timeout = 1000 * 30 // 默认15s
    this._initAxios()
    this._initMethods()
  }

  // Public Method
  _initMethods() {
    const methods = ['get', 'post', 'put', 'delete', 'patch', 'options', 'head']
    methods.forEach(method => {
      this[method] = async (path, params, formData = false) => {
        let axiosConfig = {
          timeout: this.timeout,
          method,
          url: path,
          headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        }
        switch (method) {
          case 'get':
          case 'delete':
            axiosConfig.params = params
            break
          default:
            if (formData && JSON.stringify(formData) !== undefined) {
              let formData = new FormData()
              for (let i in params) {
                formData.append(i, params[i])
              }
              axiosConfig.headers['Content-Type'] = 'multipart/form-data'
              axiosConfig.data = formData
            } else {
              axiosConfig.data = params
            }
            break
        }
        return this.axios.request(axiosConfig)
      }
    })
  }

  handleDownLoadResponse(response) {
    if (!response.headers['content-disposition']) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ code: 3000, msg: '下载文件失败' })
    }
    const fileNameRegexp = /fileName=(.+)$/
    const ret = fileNameRegexp.exec(response.headers['content-disposition'])
    let fileName = ret?.[1]
    if (!fileName) {
      fileName = decodeURIComponent(response.headers['content-disposition']?.split("''")[1] || 'download.xlsx')
    }
    let blob = new Blob([response.data])
    if ('download' in document.createElement('a')) {
      // 不是IE浏览器
      let url = window.URL.createObjectURL(blob)
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) // 下载完成移除元素
      window.URL.revokeObjectURL(url) // 释放掉blob对象
    } else {
      // IE 10+
      window.navigator.msSaveBlob(blob, fileName)
    }
    return Promise.resolve()
  }

  /**
   * 通过delete body删除
   */
  deleteByBody = (path, params) => {
    let axiosConfig = {
      timeout: this.timeout,
      method: 'delete',
      url: path,
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    }
    axiosConfig.data = params
    return this.axios.request(axiosConfig)
  }

  /**
   * 上传文件
   * @param url url
   * @param params 请求参数
   * @param onUploadProgress 进度回调 (progressEvent) => {}
   * @returns {Promise<AxiosResponse<any>>}
   */
  uploadFile(url, params, onUploadProgress = () => {}) {
    let data = new FormData()
    for (let i in params) {
      data.append(i, params[i])
    }
    return this.axios.request({
      timeout: 1000 * 60,
      method: 'post',
      url,
      data,
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress,
    })
  }

  /**
   * 下载文件
   * @param url
   * @param params
   */
  download(url, params) {
    return new Promise((resolve, reject) => {
      this.axios({
        method: 'GET',
        url,
        responseType: 'blob',
        params,
      })
        .then(response => {
          resolve(this.handleDownLoadResponse(response))
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
   * 通过post请求下载文件
   * @param url
   * @param params
   */
  downloadByPost(url, params) {
    this.axios({
      method: 'post',
      url,
      responseType: 'blob',
      data: params,
    })
      .then(response => {
        this.handleDownLoadResponse(response)
      })
      .catch(error => {
        console.log(error)
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ code: 3000, msg: '无法下载文件' })
      })
  }

  // Private Method
  _initAxios() {
    // console.log(process.env);
    this.axios = axios.create({
      baseURL: `${process.env.VUE_APP_BASE_URL + '/merchant'}`,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      paramsSerializer: {
        serialize(params) {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        },
      },
    })

    // request 拦截器
    this.axios.interceptors.request.use(
      config => {
        config.headers.version = versionConfig['admin']
        const token = store.state.userData?.token
        if (token) {
          config.headers.Authorization = token
        }
        this._showLoading()
        return config
      },
      error => {
        this._dismissLoading()
        return Promise.reject(error)
      }
    )

    // response 拦截器
    this.axios.interceptors.response.use(
      async response => {
        this._dismissLoading()
        if (response.config.responseType === 'blob') {
          if (response.data.type === 'application/json') {
            const text = await response.data.text()
            return Promise.reject(this._handleRejectResponse(JSON.parse(text)))
          } else {
            return Promise.resolve(response)
          }
        }
        if (this._handleSuccessResponse(response.data)) {
          return Promise.resolve(response.data.data)
        } else {
          return Promise.reject(this._handleRejectResponse(response.data))
        }
      },
      error => {
        this._dismissLoading()
        console.log(error)
        return Promise.reject(this._handleRejectResponse(error.response || error))
      }
    )
  }

  useLoading() {
    this.loadingCount++
    return this
  }

  _showLoading() {
    if (this.loadingCount <= 0) {
      return
    }
    store.commit('showLoading')
  }

  _dismissLoading() {
    // 还原配置
    if (this.loadingCount >= 1) {
      this.loadingCount--
    }
    store.commit('hideLoading')
  }

  _handleSuccessResponse(result) {
    switch (result.code) {
      case 200:
        // case 3011:
        return true
      case 401:
        store.commit('removeUserData')
        router.replace({ name: 'login' })
        // location.reload();
        return false
      default:
        return false
    }
  }

  _handleRejectResponse(result) {
    let msg = '网络开小差，请稍后重试！'
    let code = -999
    if (result.code === 401) {
      msg = '账号已过期，请重新登录！'
    }
    if (result && result.msg) {
      msg = result.msg
    }
    if (result && result.code) {
      code = result.code
    }
    if (!this.autoShowToast) {
      this.autoShowToast = true
    } else {
      message.error(msg || '网络开小差，请稍后重试！')
    }
    return { code, msg }
  }
}

const ajax = new Ajax()
export default ajax

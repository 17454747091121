import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store'

import BaseRoutes from '@/admin/router/modules/baseRoutes'
import noticeApi from '@/admin/api/modules/noticeApi'

const router = createRouter({
  history: createWebHashHistory(),
  routes: BaseRoutes,
})

router.beforeEach((to, from, next) => {
  if (!store.state.userData) {
    // 未登录
    if (to.meta.noPermission) {
      // 无需权限的页面
      next()
    } else if (to.name !== 'login') {
      // 需要权限的页面跳转到登录
      next({ name: 'login' })
    } else {
      next()
    }
  } else if (to.name === 'login') {
    // 已登录
    next({ name: 'home' })
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  if (to.path === from.path) {
    return
  }
  if (to.name === 'login' || to.hash) {
    return
  }
  // 刷新右下角消息数
  if (store.state.userData) {
    noticeApi.getNoticeNum().then(data => {
      store.commit('updateNoticeNum', data)
    })
  }
})

export default router

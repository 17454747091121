import ajax from './../ajax'
export default {
  /**
   * 通知消息数量

   */
  getNoticeNum() {
    return ajax.useLoading().get('/message/showUnReadMsg', null)
  },

  /**
   * 通知消息列表

   */
  getNoticeList() {
    return ajax.useLoading().get('/message', null)
  },
}

import { createApp } from 'vue'
import App from './App.vue'

import 'ant-design-vue/dist/antd.less'
import '@/common/styles/index.less'
import './../admin/style/index.less'

import '@/common/utils'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import AdvancedFormat from 'dayjs/plugin/advancedFormat'
import CustomParseFormat from 'dayjs/plugin/customParseFormat'
import Duration from 'dayjs/plugin/duration'
import IsToday from 'dayjs/plugin/isToday'
dayjs.locale('zh-cn')
dayjs.extend(AdvancedFormat)
dayjs.extend(CustomParseFormat)
dayjs.extend(Duration)
dayjs.extend(IsToday)

import router from '@/admin/router'
import store from './../admin/store'
import initAppConfig from '@/common/utils/importApp'

const app = createApp(App)
initAppConfig(app, store, router)
app.mount('#app')

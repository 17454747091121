<template>
  <a-menu
    v-model:selectedKeys="selectedKeys"
    v-model:openKeys="defaultOpenMenus"
    class="main-menu"
    mode="inline"
    :inline-collapsed="collapsed"
    theme="dark"
  >
    <a-sub-menu v-for="route in menuItems" :key="route.name" class="sub-menu" :style="{ boxShadow: 'none' }">
      <template #icon>
        <component :is="Icons[route.meta.icon]"></component>
      </template>
      <template #title>{{ route.meta.title }}</template>
      <a-menu-item v-for="child in route.children" :key="child.name" class="menu-item">
        <router-link :to="{ name: child.meta.defaultChild || child.name }">{{ child.meta.title }}</router-link>
      </a-menu-item>
    </a-sub-menu>
  </a-menu>
</template>

<script>
import router from '@/admin/router'
import { getCurrentInstance, reactive, toRefs } from 'vue'
import * as Icons from '@ant-design/icons-vue'

export default {
  // components: {Icon},
  name: 'MainMenu',
  props: {
    router: {
      type: Object,
      default: null,
    },
  },

  setup() {
    const state = reactive({
      collapsed: false,
      selectedKeys: [],
    })

    const selectMenu = () => {
      const list = getCurrentInstance().proxy.$route.matched.filter(r => !!r.meta.title)
      state.selectedKeys = [list[list.length > 3 ? list.length - 2 : list.length - 1].name] // 选中状态
    }
    selectMenu() // 选中菜单
    return {
      ...toRefs(state),
      Icons,
    }
  },
  computed: {
    menuItems() {
      return this.getMenuData(router?.options?.routes?.find(item => item.name === 'home')?.children)
    },
    defaultOpenMenus() {
      return this.menuItems.map(menu => menu.name)
    },
  },
  methods: {
    getMenuData(routes) {
      if (!routes) {
        return []
      }
      routes.forEach(route => {
        if (route.children) {
          route.children = this.getMenuData(route.children)
        }
      })
      return routes.filter(route => route.meta.isMenu)
    },
  },
}
</script>

<style scoped lang="less">
.main-menu {
  .sub-menu {
    :deep(.ant-menu-inline.ant-menu-sub) {
      box-shadow: none;
    }
  }

  :deep(.ant-menu-item-selected) {
    font-weight: bold;
  }
}
</style>

<template>
  <a-config-provider :locale="locale">
    <div>
      <div v-if="loadingRequestCount > 0" class="loading-layer">
        <div class="global-loading">
          <p class="text-align-center">加载中...</p>
        </div>
      </div>
      <router-view></router-view>
      <import-result-modal
        v-if="showImportResultModal"
        v-model="showImportResultModal"
        :import-result="importResult"
      ></import-result-modal>
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import { mapState } from 'vuex'

export default {
  name: 'App',
  data() {
    return {
      locale: zhCN,
      showImportResultModal: false,
      importResult: {},
    }
  },
  computed: {
    ...mapState(['loadingRequestCount']),
  },
  methods: {
    showImportResult(result) {
      this.showImportResultModal = true
      this.importResult = result
    },
  },
}
</script>

<style lang="less" scoped>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .loading-layer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0);
    z-index: 99999;
    img {
      border-radius: 5px;
      padding: 10px;
      background: #fff;
    }

    .global-loading {
      margin-bottom: 18%;

      &::before {
        content: '';
        display: block;
        width: 132px;
        height: 120px;
        border-radius: 5px;
        box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
        background: url('@/common/assets/images/loading.gif') center 15px no-repeat;
        background-color: #fff;
        margin: 0 auto;
      }

      p {
        margin-top: -40px;
        color: #666;
      }
    }
  }
}
</style>

<template>
  <div style="overflow: hidden">
    <a-layout id="home-page">
      <a-layout-sider class="home-sidebar">
        <!--        <div class="logo">心康购-商户后台</div>-->
        <div class="margin-top-10 margin-bottom-10 text-align-center">
          <img class="logo" src="../../assets/global/icon-title-logo.png" />
        </div>
        <main-menu></main-menu>
      </a-layout-sider>
      <a-layout class="page-content">
        <a-layout-header class="header">
          <a-row>
            <a-col span="12" class="breadcrumb-wrapper">
              <breadcrumb :routes="$route.matched.filter(r => !!r.meta.title).slice(2)"></breadcrumb>
            </a-col>
            <a-col span="12" class="text-align-right">
              <a-dropdown>
                <span style="line-height: 32px; display: inline-block"
                  ><a-avatar shape="square"
                    ><template #icon><UserOutlined /></template></a-avatar
                  >&emsp;{{ userData ? userData.loginName : '' }}</span
                >
                <template #overlay>
                  <a-menu @click="onClickDropdown">
                    <a-menu-item key="2">
                      <SecurityScanOutlined />
                      修改密码
                    </a-menu-item>
                    <a-menu-divider />
                    <a-menu-item key="privacy">
                      <SecurityScanOutlined />
                      隐私设置
                    </a-menu-item>
                    <a-menu-divider />
                    <a-menu-item key="1">
                      <LogoutOutlined />
                      退出登录
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </a-col>
          </a-row>
        </a-layout-header>
        <a-layout-content
          id="mainContainer"
          ref="mainContainer"
          class="main-container"
          :class="{ scroll: hasScrollbar }"
        >
          <router-view></router-view>
        </a-layout-content>
        <a-badge
          class="noticeBtn"
          :offset="[-10, 0]"
          :count="noticeNum"
          :overflow-count="99"
          @click="openNewNoticeModal"
        >
          <img src="../../../common/assets/images/icon-news.png" alt="" />
        </a-badge>
      </a-layout>
    </a-layout>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import MainMenu from './components/MainMenu'
import ChangePasswordModal from './components/ChangePasswordModal'
import newsNoticeModal from './components/newsNoticeModal'
import { Modal } from 'ant-design-vue'
import store from '@/admin/store'
import router from '@/admin/router'
import { UserOutlined, SecurityScanOutlined, LogoutOutlined } from '@ant-design/icons-vue'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
export default {
  components: {
    MainMenu,
    UserOutlined,
    SecurityScanOutlined,
    LogoutOutlined,
  },

  setup() {
    const routes = computed(() => {
      return useRoute()
        .matched.filter(r => !!r.meta.title)
        .slice(2)
    })
    let hasScrollbar = ref(false)

    return {
      routes,
      hasScrollbar,
    }
  },
  computed: {
    ...mapState(['userData', 'noticeNum']),
  },
  // mounted () {
  //   // 监听子节点变化来判断是否出现滚动条
  //   new MutationObserver(() => {
  //     this.hasScrollbar = this.$refs.mainContainer.$el.scrollHeight !== this.$refs.mainContainer.$el.clientHeight;
  //   }).observe(this.$refs.mainContainer.$el, {childList: true, subtree: true});
  // },
  // data () {
  //   return {
  //     collapsed: false,
  //     hasScrollbar: false
  //   };
  // },
  methods: {
    openNewNoticeModal() {
      this.openModal(newsNoticeModal)
    },
    onClickDropdown({ key }) {
      if (key === '1') {
        Modal.confirm({
          title: '退出登录',
          content: '是否要退出登录？',
          centered: true,
          onOk: () => {
            return new Promise(resolve => {
              store.commit('removeUserData')
              router.replace({ name: 'login' })
              resolve(0)
            })
          },
        })
      } else if (key === '2') {
        this.openModal(ChangePasswordModal)
      } else if (key === 'privacy') {
        location.href = `https://www.sinoxk.com/terms/#/mallAdmin?appName=心康商户后台&encodeInfo=${this.userData.encryptionStr}`
      }
    },
    ...mapMutations(['removeUserData']),
  },
}
</script>
<style lang="less" scoped>
#home-page {
  .home-sidebar {
    height: 100vh;
    :deep(.ant-layout-sider-children) {
      display: flex;
      flex-direction: column;
    }

    .logo {
      height: 32px;
      width: 114px;
      margin-left: -3px;
      margin-bottom: 20px;
      margin-top: 20px;
    }

    .main-menu {
      flex: 1;
      overflow-y: auto;
      &::-webkit-scrollbar-track {
        background-color: #2b3540;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #c9c9c9;
      }
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #fff;
      }
    }
  }

  .page-content {
    overflow: auto;
    height: 100vh;
    position: relative;
  }

  .header {
    background-color: #fff;
    padding: 0 24px;
    min-width: 1080px;
    .breadcrumb-wrapper {
      padding: 24px 0;
    }
  }

  .main-container {
    background: #f5f5f5;
    min-width: 1080px;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    .body {
      margin: 24px;
      padding: 24px;
      background: #fff;
      min-height: 700px;
    }
    &.scroll {
      .body {
        margin-right: 7px;
      }
    }
  }
}
.noticeBtn {
  position: fixed;
  background: #f0f7ff;
  width: 64px;
  height: 64px;
  border-radius: 32px;
  bottom: 8%;
  right: 3%;
  z-index: 999;
  cursor: pointer;
}
</style>

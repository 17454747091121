<template>
  <a-modal
    v-model:visible="modalVisible"
    :mask-closable="false"
    title="修改密码"
    centered
    class="change-password-modal"
    @ok="onOk"
  >
    <a-row>
      <a-col :span="24">
        <a-form ref="form" :model="form" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
          <a-form-item label="原密码" name="password">
            <a-input-password v-model:value="form.password" :maxlength="50" />
          </a-form-item>
          <a-form-item label="新密码" name="newPassword">
            <a-input-password v-model:value="form.newPassword" :maxlength="50" />
          </a-form-item>
          <a-form-item label="确认密码" name="checkPassword">
            <a-input-password v-model:value="form.checkPassword" :maxlength="50" />
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import accountApi from '@/admin/api/modules/accountApi'
import { message } from 'ant-design-vue'

export default {
  name: 'ChangePasswordModal',
  props: {
    storeUserCode: {
      type: String,
      default: '',
    },
  },
  data() {
    let validatePass = (rule, value) => {
      if (!value) {
        // callback(new Error('请填写新密码'))
        return Promise.reject(new Error('请填写新密码'))
      } else {
        if (this.form.checkPassword !== '') {
          this.$refs.form.validateFields(['checkPassword'])
        }
        // callback()
        return Promise.resolve()
      }
    }
    let validatePass2 = (rule, value) => {
      if (!value) {
        // callback(new Error('请再次填写密码'))
        return Promise.reject(new Error('请再次填写密码'))
      } else if (value !== this.form.newPassword) {
        // callback(new Error('两次填写的密码不匹配'))
        return Promise.reject(new Error('两次填写的密码不匹配'))
      } else {
        // callback()
        return Promise.resolve()
      }
    }
    return {
      modalVisible: true,
      form: {
        password: '',
        newPassword: '',
        checkPassword: '',
      },
      rules: {
        password: [{ required: true, message: '请填写原密码', trigger: 'change' }],
        newPassword: [{ validator: validatePass, trigger: 'change' }],
        checkPassword: [{ validator: validatePass2, trigger: 'change' }],
      },
    }
  },
  methods: {
    async onOk() {
      try {
        await this.$refs.form.validateFields()
        accountApi.changePassword(this.form.password, this.form.newPassword, this.form.checkPassword).then(() => {
          message.success('密码修改成功')
          this.modalVisible = false
        })
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>

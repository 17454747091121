import Login from '@/admin/views/Login.vue'
import homeRoutes from '@/admin/router/modules/homeRoutes'

const baseRoutes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: {
      title: '登录页面',
    },
  },
  {
    path: '/notFound',
    name: 'notFound',
    component: Login,
    meta: {
      title: '404',
    },
  },
  homeRoutes,
]

export default baseRoutes

<template>
  <div class="login-page">
    <div class="navbar">
      <div class="navbar-inner">
        <div class="container">
          <div class="navbar-header">
            <a class="navbar-brand" href="#/app/home"> 心康购 </a>
          </div>
        </div>
      </div>
    </div>
    <div id="content" class="content" style="position: relative">
      <div class="container">
        <div class="left">
          <img src="../../common/assets/images/login/banner.png" />
        </div>
        <div class="lock-container animated fadeInDown">
          <div class="lock-box text-align-center">
            <div class="lock-username">欢迎登录心康购</div>
            <div class="lock-form">
              <a-form hide-required-mark :model="form" layout="vertical" :rules="rules">
                <a-form-item label="用户名" :colon="false" class="form-group margin-bottom-20" prop="username">
                  <a-input v-model:value="form.username" size="large" :maxlength="32" />
                </a-form-item>
                <a-form-item label="密码" :colon="false" class="form-group margin-bottom-20" prop="password">
                  <a-input-password v-model:value="form.password" size="large" :maxlength="50" />
                </a-form-item>
                <a-form-item class="form-group margin-bottom-10" prop="captcha">
                  <a-input v-model:value="form.captcha" size="large" placeholder="验证码" :maxlength="4" />
                  <img ref="captcha" class="captcha" @click="refreshCaptcha()" />
                </a-form-item>
                <a-form-item class="form-group terms-wrapper lightgray">
                  <a-checkbox v-model:checked="agreeTerms">已同意</a-checkbox>
                  <span class="text contract" @click="showTerms = true">《“心康购”电商平台服务协议》</span>和<span
                    class="text contract"
                    @click="goPrivacy()"
                    >《隐私政策》</span
                  >
                </a-form-item>

                <a-form-item class="form-group margin-top-10 margin-bottom-10">
                  <a-button
                    id="submit"
                    type="primary"
                    size="large"
                    html-type="submit"
                    :loading="loading"
                    style="width: 100%"
                    @click="onSubmit"
                    >登录
                  </a-button>
                </a-form-item>
              </a-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer text-align-center">
      <p id="copyRight" class="darkgray"></p>
      <p class="darkgray">粤ICP备<a href="http://beian.miit.gov.cn">17095998号</a> 粤公网安备 44010602002837号</p>
    </div>
    <a-modal
      v-model:visible="showTerms"
      :mask-closable="false"
      :body-style="{ paddingBottom: 0 }"
      title="“心康购”电商平台服务协议"
      centered
      width="824px"
    >
      <iframe
        style="height: 500px; width: 100%; border: 0; overflow-y: auto"
        src="https://www.sinoxk.com/terms/mallAdmin/%E5%BF%83%E5%BA%B7%E8%B4%AD%E5%95%86%E6%88%B7%E5%90%8E%E5%8F%B0-%E5%BF%83%E5%BA%B7%E8%B4%AD%E7%94%B5%E5%95%86%E5%B9%B3%E5%8F%B0%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.html"
      ></iframe>
      <template #footer>
        <p class="MsoNormal text-align-left">
          <span
            style="font-family: '等线'; mso-bidi-font-family: 'Times New Roman'; font-size: 12px; mso-font-kerning: 1pt"
            >本协议为线上协议，如果双方另有线下书面协议，协议内容具有同等法律效力，约定存在冲突的，以线下书面协议约定为准。</span
          >
        </p>
      </template>
    </a-modal>
  </div>
</template>

<script setup>
import accountApi from '@/admin/api/modules/accountApi'

import router from '../router'
import { onMounted, reactive, ref } from 'vue'
import { Form, message } from 'ant-design-vue'
import store from '@/admin/store'
import genUUIDv4 from '@/common/utils/uuid'

const useForm = Form.useForm

let id = ''
const showTerms = ref(false)
const captcha = ref(null)
const agreeTerms = ref(false)

const form = reactive({
  username: '',
  password: '',
  captcha: '',
})

const rules = reactive({
  username: [{ required: true, trigger: 'change', message: '' }],
  password: [{ required: true, trigger: 'change', message: '' }],
  captcha: [{ required: true, trigger: 'change', message: '' }],
})

const { validate } = useForm(form, rules)

const onSubmit = () => {
  validate()
    .then(() => {
      if (!agreeTerms.value) {
        message.error('同意《“心康购”电商平台服务协议》和《隐私政策》才能继续！')
        return
      }
      accountApi
        .login(form.username, form.password, form.captcha, id)
        .then(data => {
          store.commit('updateUserData', data)
          router.replace({ name: 'home' })
        })
        .catch(() => {
          refreshCaptcha()
        })
    })
    .catch(() => {
      message.error('请正确填写所有内容！')
    })
}

const refreshCaptcha = () => {
  id = genUUIDv4()
  captcha.value.src = process.env.VUE_APP_BASE_URL + '/code.jpg?id=' + id
}

const goPrivacy = () => {
  window.open(
    'https://www.sinoxk.com/terms/mallAdmin/%E5%BF%83%E5%BA%B7%E8%B4%AD%E5%95%86%E6%88%B7%E5%90%8E%E5%8F%B0-%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96.html',
    '_blank'
  )
}

onMounted(() => {
  refreshCaptcha() // 刷新验证码
})
</script>

<style lang="less" scoped>
:deep(.ant-form-item-label) {
  padding: 0;
}

:deep(.ant-form-item-with-help .ant-form-item-explain) {
  min-height: 0; // 不显示form-item错误提示
}

.login-page {
  .navbar {
    height: 100px;
    line-height: 100px;
    min-width: 1280px;
    padding: 0;
    border: none;

    .navbar-inner {
      background-color: #fff;
      width: 960px;
      margin: 0 auto;
    }

    .navbar-brand {
      width: 72px;
      height: 33px;
      font-size: 24px;
      font-weight: 500;
      color: rgba(2, 109, 229, 1);
    }
  }

  .content {
    padding: 90px 0;
    background-color: #1762e0;
    min-width: 1280px;

    .container {
      width: 960px;
      margin: 0 auto;
    }

    .left {
      width: 480px;
      position: absolute;
      padding-top: 60px;
    }

    .lock-container {
      margin: 0 0 0 574px;

      .lock-box {
        position: relative;
        background: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        background-image: linear-gradient(to top, #fff, #f3f3f3);
        width: 440px;
        height: 464px;
        border-radius: 5px;
        padding: 40px 32px 20px;

        .lock-username {
          font-size: 24px;
          font-weight: 500;
          color: rgba(34, 34, 34, 1);
          line-height: 24px;
          text-align: center;
        }

        .lock-form {
          margin-top: 40px;
          text-align: left;

          input {
            padding-left: 15px;
            border-radius: 2px;
          }

          .form-group {
            :deep(.ant-form-item-label) {
              line-height: 25px;

              label {
                color: #222222;
              }
            }

            :deep(.ant-form-explain) {
              display: none;
            }

            :deep(.ant-form-item-control-wrapper) {
              padding-left: 0;
            }

            &.terms-wrapper :deep(.ant-form-item-control) {
              line-height: 30px;
            }

            :deep(.ant-checkbox-wrapper) {
              color: #999;
            }
          }

          img.captcha {
            position: absolute;
            width: 105px;
            height: 38px;
            top: 1px;
            right: 1px;
            -webkit-box-shadow: none;
            box-shadow: none;
            border-radius: 0;
            border-left: solid 1px #d9d9d9;
            //border-right: solid 1px #d9d9d9;
            cursor: pointer;
          }

          .contract {
            position: relative;
            z-index: 11;
            display: inline-block;
            margin: 0;
            line-height: 20px;
            min-height: 18px;
            min-width: 18px;
            font-weight: normal;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .footer p {
    margin: 15px;
  }
}
</style>

import Vuex from 'vuex'

import product from './modules/product'

// vuex持久化
import createPersistedState from 'vuex-persistedstate'
// 加密存储
import SecureLS from 'secure-ls'
const ls = new SecureLS({ isCompression: true })

let savedState = ls.get('vuex')
if (savedState) {
  savedState = JSON.parse(savedState)
}

export default new Vuex.Store({
  modules: {
    product,
  },
  state: {
    ...savedState,
    noticeNum: 0,
    loadingRequestCount: 0,
  },
  mutations: {
    updateUserData(state, userData) {
      state.userData = { ...state.userData, ...userData }
    },
    updateNoticeNum(state, num) {
      state.noticeNum = num
    },
    showLoading(state) {
      state.loadingRequestCount = state.loadingRequestCount + 1
    },
    hideLoading(state) {
      if (state.loadingRequestCount) {
        state.loadingRequestCount = state.loadingRequestCount - 1
      }
    },
    removeUserData(state) {
      state.userData = undefined
    },
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => {
          if (key === 'vuex') {
            if (!ls.get(key)) {
              return {}
            }
            savedState = JSON.parse(ls.get(key))
            savedState.loadingRequestCount = 0
            return JSON.stringify(savedState)
          } else {
            return ls.get(key)
          }
        },
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key),
      },
    }),
  ],
})

import productApi from '../../api/modules/productApi'

const product = {
  namespaced: true,
  state: {
    checkCount: {
      // 审核数量
      auditStatus1Count: 0, // 审核通过
      auditStatus2Count: 0, // 审核中
      auditStatus3Count: 0, // 审核失败
    },

    // 正常异常商品数量
    productCount: {
      enableFlag0Count: 0,
      enableFlag1Count: 0,
    },

    // 上下架
    storeProductCount: {
      enableFlag1Count: 0,
      enableFlag0Count: 0,
    },
  },
  mutations: {
    updateCheckCount(state, payload) {
      state.checkCount = payload
    },

    // 正常异常商品数量
    updateProductCount(state, payload) {
      state.productCount = payload
    },

    // 上下架
    updateStoreProductCount(state, payload) {
      state.storeProductCount = payload
    },
  },

  actions: {
    /**
     * 加载审核数量
     * @param commit
     */
    loadCheckCount({ commit }) {
      productApi.getCheckListCount().then(data => {
        commit('updateCheckCount', data)
      })
    },

    /**
     * 正常异常商品数量
     * @param commit
     */
    loadProductCount({ commit }) {
      productApi.getProductMsgCount().then(data => {
        commit('updateProductCount', data)
      })
    },

    /**
     * 上下架
     */
    loadStoreProductCount({ commit }, storeCode) {
      productApi.getStoreProductCount(storeCode).then(data => {
        commit('updateStoreProductCount', data)
      })
    },
  },

  getters: {},
}

export default product
